import c from "classnames";

import { useMediaQueries } from "@/hooks/useMediaQueries";
import { ButtonProps, ButtonVariants } from "@/types/inputFields";

import Styles from "./popUpDrawerButtons.module.scss";

import { Button } from "../FormElements/Button";

type Props = {
	acceptButtonProps?: ButtonProps;
	closeButtonProps?: ButtonProps;
	alternativeButtonProps?: ButtonProps;
	closeOnBottom?: boolean;
	transparent?: boolean;
	stickyButtons?: boolean;
};

export const PopUpDrawerButtons = ({
	acceptButtonProps,
	alternativeButtonProps,
	closeButtonProps,
	closeOnBottom = false,
	transparent = false,
	stickyButtons = false,
}: Props) => {
	const { isMobile } = useMediaQueries();

	const hasOneButton =
		[acceptButtonProps, alternativeButtonProps, closeButtonProps].filter(
			(val) => !!val
		).length === 1;

	const has2Button =
		[acceptButtonProps, alternativeButtonProps, closeButtonProps].filter(
			(val) => !!val
		).length === 2;

	return (
		<div
			className={c(Styles.wrapper, {
				[Styles.singleButton]: hasOneButton,
				[Styles.dualButton]: has2Button,
				[Styles.closeOnBottom]: closeOnBottom,
				[Styles.transparent]: transparent,
				[Styles.stickyButtons]: stickyButtons,
			})}
		>
			{acceptButtonProps && alternativeButtonProps && (
				<Button
					type="button"
					{...acceptButtonProps}
					width={
						isMobile || acceptButtonProps.width === "full" ? "full" : "relative"
					}
				/>
			)}

			{alternativeButtonProps && (
				<Button
					type="button"
					{...alternativeButtonProps}
					variant={alternativeButtonProps.variant || ButtonVariants.clearLight}
					width={
						isMobile || alternativeButtonProps.width === "full"
							? "full"
							: "relative"
					}
				/>
			)}

			{closeButtonProps && (
				<Button
					type="button"
					maintainPadding
					{...closeButtonProps}
					variant={closeButtonProps.variant || ButtonVariants.ghost}
					width={
						isMobile || closeButtonProps.width === "full" ? "full" : "relative"
					}
				/>
			)}

			{acceptButtonProps && !alternativeButtonProps && (
				<Button
					type="button"
					{...acceptButtonProps}
					width={
						isMobile || acceptButtonProps.width === "full" ? "full" : "relative"
					}
				/>
			)}
		</div>
	);
};
