import { TouchEventHandler, useCallback, useState } from "react";

type Props = {
	children: React.ReactNode;
	onSwipeUp?: VoidFunction;
	onSwipeDown?: VoidFunction;
	onSwipeLeft?: VoidFunction;
	onSwipeRight?: VoidFunction;
};

export const SwipeContainer = ({
	children,
	onSwipeUp,
	onSwipeDown,
	onSwipeLeft,
	onSwipeRight,
}: Props) => {
	const [touchStartX, setTouchStartX] = useState<number | null>(null);
	const [touchStartY, setTouchStartY] = useState<number | null>(null);
	const [touchEndX, setTouchEndX] = useState<number | null>(null);
	const [touchEndY, setTouchEndY] = useState<number | null>(null);

	const minSwipeDistance = 50;

	const onTouchStart: TouchEventHandler<HTMLDivElement> = useCallback((e) => {
		setTouchEndX(null);
		setTouchEndY(null);
		setTouchStartX(e.targetTouches[0].clientX);
		setTouchStartY(e.targetTouches[0].clientY);
	}, []);

	const onTouchMove: TouchEventHandler<HTMLDivElement> = useCallback((e) => {
		setTouchEndX(e.targetTouches[0].clientX);
		setTouchEndY(e.targetTouches[0].clientY);
	}, []);

	const onTouchEnd = useCallback(() => {
		if (!touchStartX || !touchEndX || !touchStartY || !touchEndY) {
			return;
		}

		const distanceX = touchStartX - touchEndX;
		const distanceY = touchStartY - touchEndY;

		const isLeftSwipe = distanceX > minSwipeDistance;
		const isRightSwipe = distanceX < -minSwipeDistance;
		const isDownSwipe = distanceY < minSwipeDistance;
		const isUpSwipe = distanceY > -minSwipeDistance;

		switch (true) {
			case isLeftSwipe:
				onSwipeLeft?.();
				break;
			case isRightSwipe:
				onSwipeRight?.();
				break;
			case isDownSwipe:
				onSwipeDown?.();
				break;
			case isUpSwipe:
				onSwipeUp?.();
				break;
		}
	}, [
		onSwipeDown,
		onSwipeLeft,
		onSwipeRight,
		onSwipeUp,
		touchEndX,
		touchEndY,
		touchStartX,
		touchStartY,
	]);

	return (
		<div
			onTouchStart={onTouchStart}
			onTouchMove={onTouchMove}
			onTouchEnd={onTouchEnd}
		>
			{children}
		</div>
	);
};
