import c from "classnames";

import { SlapLabelVariants } from "@/types/layout";

import Styles from "./slapLabel.module.scss";

type Props = {
	children: React.ReactNode;
	className?: string;
	variant: SlapLabelVariants;
	slim?: boolean;
	wrap?: boolean;
	bulky?: boolean;
	thick?: boolean;
	fullWidth?: boolean;
	center?: boolean;
	soft?: boolean;
	tiny?: boolean;
};

export const SlapLabel = ({
	variant,
	children,
	className = "",
	slim = false,
	wrap = false,
	bulky = false,
	fullWidth = false,
	center = false,
	soft = false,
	tiny = false,
	thick = false,
}: Props) => {
	return (
		<div
			className={c(Styles.default, {
				[Styles.attention]: variant === SlapLabelVariants.attention,
				[Styles.subtle]: variant === SlapLabelVariants.subtle,
				[Styles.offers]: variant === SlapLabelVariants.offers,
				[Styles.error]: variant === SlapLabelVariants.errors,
				[Styles.grey]: variant === SlapLabelVariants.grey,
				[Styles.bulky]: bulky,
				[Styles.thick]: thick,
				[Styles.slim]: slim,
				[Styles.wrap]: wrap,
				[Styles.center]: center,
				[Styles.fullWidth]: fullWidth,
				[Styles.soft]: soft,
				[Styles.tiny]: tiny,
				[className]: !!className,
			})}
		>
			{children}
		</div>
	);
};
