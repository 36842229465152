import { MutableRefObject, useCallback, useEffect, useRef } from "react";

import { useMediaQueries } from "./useMediaQueries";

export const useFixedOnIOS = ({ dontMove = false }: { dontMove?: boolean }) => {
	const containerRef =
		useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

	const viewport = useRef(
		typeof window !== "undefined" && window.visualViewport?.height
	);

	const resizeHandler = useCallback(() => {
		if (!viewport || !containerRef.current || typeof window === "undefined") {
			return;
		}

		const height = window.visualViewport?.height;

		if (!height || !viewport.current) {
			return;
		}

		if (
			(height >= Math.floor(viewport.current) - 10 &&
				height <= Math.floor(viewport.current) + 10) ||
			height > viewport.current ||
			dontMove
		) {
			containerRef.current.style.bottom = "0px";
			return;
		}

		containerRef.current.style.bottom = `${
			viewport.current - height - (window.visualViewport?.offsetTop || 0)
		}px`;
	}, [dontMove]);

	const { isDesktop } = useMediaQueries();

	useEffect(() => {
		const isIOS =
			/(iPad|iPhone|iPod)/g.test(navigator.userAgent) ||
			(navigator.userAgent.includes("Mac") && "ontouchend" in document);

		if (typeof window === "undefined" || isDesktop || !isIOS) {
			return;
		}

		window.visualViewport?.addEventListener("resize", resizeHandler);
		window.visualViewport?.addEventListener("scroll", resizeHandler);

		return () => {
			window.visualViewport?.removeEventListener("resize", resizeHandler);
			window.visualViewport?.removeEventListener("scroll", resizeHandler);
		};
	}, [isDesktop, resizeHandler]);

	return {
		containerRef,
	};
};
